<template>
  <div class="container">
    <!-- 按钮列表 -->
    <div class="btnList1" ref="btnList">
      <el-button class="itemBtn btnColor" @click="addMember()">新建</el-button>
      <el-button class="itemBtn btnColor">删除</el-button>
      <el-button class="itemBtn btnColor" @click="modifybut()">修改</el-button>

      <!-- <el-button class="itemBtn btnColor" @click="myModel">列表设置</el-button>
      <el-dropdown @command="handleCommand_dc">
        <el-button class="dropdownBtn">
          导出<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="dc">导出</el-dropdown-item>
          <el-dropdown-item command="dcmb">下载导出模板</el-dropdown-item>
          <el-dropdown-item command="scmb">保存(上传)模板</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown> -->
    </div>
    <!-- 查询条件 -->
    <el-form :model="queryData">
      <div
        ref="queryCriteria"
        class="HeadInputbox"
        :class="{ HeadInputbox1: judge, active: isActive }"
      >
        <div class="item_right">
          <div class="singleinp">
            <div class="singleinpleft">值:</div>
            <div class="singleinpright">
              <el-input
                class="input"
                v-model.trim="queryData.areaContryNameCn"
                placeholder="请输入"
                clearable
              ></el-input>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">参数备注:</div>
            <div class="singleinpright">
              <el-input
                class="input"
                v-model.trim="queryData.areaContryNameCn"
                placeholder="请输入"
                clearable
              ></el-input>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">参数名称:</div>
            <div class="singleinpright">
              <el-input
                class="input"
                v-model.trim="queryData.areaContryNameCn"
                placeholder="请输入"
                clearable
              ></el-input>
            </div>
          </div>
        </div>
        <div class="item_left">
          <div class="singleinpbtn">
            <el-button
              class="seniorbut"
              @click="queryBtn_ok"
              icon="el-icon-search"
              >查询</el-button
            >
            <el-tooltip content="查询项恢复初始状态" placement="top">
              <div class="condition" @click="queryBtn_cz">清除</div>
            </el-tooltip>
            <el-tooltip content="查询更多条件" placement="top">
              <div class="condition" @click="more">
                {{ MoreConditions }}
              </div>
            </el-tooltip>
          </div>
        </div>
      </div>
    </el-form>

    <!-- 表格 -->
    <div
      class="tableBox"
      ref="tableWrapper"
      :class="{ tableBox: TableStyle, tableBox1: fullscreen }"
    >
      <div v-if="fullscreen == false">
        <div class="el-table__common-options">
          <el-dropdown @command="handleCommandTabs">
            <el-button class="righttagICon">
              <i class="el-icon-more"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="a">表头设置</el-dropdown-item>
              <el-dropdown-item command="b">导出</el-dropdown-item>
              <el-dropdown-item command="c">下载导出模板</el-dropdown-item>
              <el-dropdown-item command="d">保存(上传)模板</el-dropdown-item>
              <!-- <el-dropdown-item command="e">全屏显示</el-dropdown-item> -->
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <el-table
        :height="tableHeigth"
        ref="mytable"
        :data="tableData"
        :row-class-name="tableRowClassName"
        highlight-current-row
        :header-cell-style="{ background: '#f0f0f0' }"
        border
        @selection-change="handleSelectionChange"
      >
        <el-table-column fixed="left" label="序号" type="index" width="60">
        </el-table-column>
        <el-table-column fixed="left" type="selection" width="60">
        </el-table-column>
        <template v-for="(item, index) in myTableHeard">
          <el-table-column
            :key="index"
            :label="item.name"
            :prop="item.field"
            sortable
            :min-width="item.width"
            :data-isTotal="item.isTotal"
            v-if="item && item.isShow"
          >
          </el-table-column>
        </template>
      </el-table>
    </div>
    <!-- //排序 -->
    <div>
      <el-dialog
        title="自定义表头排序"
        :visible.sync="show_sortTableHeard"
        append-to-body
        width="40%"
        center
      >
        <div>
          <h5 style="margin: 0">上下拖动可以排序,需要显示的列请打钩√</h5>
          <div class="titles_dialog">
            <el-checkbox label="全选" v-model="allCheck" @change="checkAll">
            </el-checkbox>
            <div>
              <span>模板名称 : </span>
              <input class="mdTitName" type="text" v-model="sortName" />
            </div>
            <div v-if="tabelHeadeTitle">
              {{ selectLength }} / {{ tabelHeadeTitle.length || 0 }}
            </div>
          </div>
          <draggable v-model="tabelHeadeTitle">
            <transition-group>
              <div
                class="daraStyle"
                v-for="(element, index) in tabelHeadeTitle"
                :key="element.field"
              >
                <el-checkbox
                  class="rowChecks"
                  :label="element.id"
                  v-model="element.isShow"
                  @change="checktab(index, $event)"
                >
                  {{ element.name }}
                </el-checkbox>
                <div class="cloWidth">
                  <div class="cloLabel">列宽:</div>
                  <el-input
                    class="cloInput"
                    type="number"
                    v-model="element.width"
                  ></el-input>
                </div>
                <div class="daraNum">{{ element.sort }}</div>
              </div>
            </transition-group>
          </draggable>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="cancelSortHeard">取 消</el-button>
          <el-button @click="confirmOk" type="primary" style="margin-left: 50px"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>
    <!-- 分页组件 -->
    <paging
      ref="pags"
      :pageNum="pageNum"
      :total="total"
      :sizeList="sizeList"
      :size="size"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    ></paging>

    <!-- 自定义排序表头 -->
    <div>
      <el-dialog
        title="上传编辑好的导出模板"
        :visible.sync="show_upload"
        append-to-body
        width="40%"
        center
      >
        <div>
          <el-upload
            :action="uploadUrl"
            :show-file-list="true"
            :file-list="fileList"
            :limit="1"
            :http-request="uploadSectionFile"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">上传模板</div>
          </el-upload>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="show_upload = false">关 闭</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 会员信息弹窗 -->
    <div></div>
    <!-- 打包批次号-目的地-渠道代理获取申请打包单信息 -->
    <div>
      <el-dialog
        title="申请打包单信息"
        :visible.sync="dia_sqdb"
        width="40%"
        @close="handleClose_sqdb"
      >
        <div style="margin-bottom: 10px">
          <!--  -->
          <el-input placeholder="请输入打包批次号查询" v-model="daBaoPiCi_s">
            <template slot="append">
              <el-button class="btnColor" @click="daBaoQueryBtn"
                >查询</el-button
              >
            </template>
          </el-input>
        </div>
        <div>
          <el-table
            :data="sqdbData"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange_sqdb"
          >
            <el-table-column type="selection" width="60"> </el-table-column>
            <el-table-column prop="nickname" label="会员名称">
            </el-table-column>
            <el-table-column prop="memberId" label="会员号"> </el-table-column>
          </el-table>
          <!-- 分页组件 -->
          <paging
            ref="pags"
            :pageNum="pageNum_sqdb"
            :total="total_sqdb"
            :sizeList="sizeList_sqdb"
            :size="size_sqdb"
            @handleSizeChange="handleSizeChange_sqdb"
            @handleCurrentChange="handleCurrentChange_sqdb"
          ></paging>
        </div>
        <span slot="footer" class="dialog-footer">
          <!-- <el-button @click="dia_sqdb = false">取 消</el-button> -->
          <el-button
            :disabled="selectSqdbData.length == 0"
            type="primary"
            @click="savesqdbBtn"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
    <!-- 根据拒收状态获取拒收单信息 -->
    <div>
      <el-dialog
        title="拒收单信息"
        :visible.sync="dia_jushou"
        width="40%"
        @close="handleClose_jushou"
      >
        <div>
          <el-table
            :data="juShouInfoData"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange_js"
          >
            <el-table-column type="selection" width="60"> </el-table-column>
            <el-table-column prop="courierNumber" label="快递单号">
            </el-table-column>
            <el-table-column prop="handleResult" label="处理结果">
            </el-table-column>
            <el-table-column prop="handleStatus" label="处理状态">
            </el-table-column>
            <el-table-column prop="handleTime" label="处理时间">
            </el-table-column>
          </el-table>
          <!-- 分页组件 -->
          <paging
            ref="pags"
            :pageNum="pageNum_js"
            :total="total_js"
            :sizeList="sizeList_js"
            :size="size_js"
            @handleSizeChange="handleSizeChange_js"
            @handleCurrentChange="handleCurrentChange_js"
          ></paging>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dia_jushou = false">取 消</el-button>
          <el-button type="primary" @click="saveJuShouBtn">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 出入库,打包单信息弹窗 -->
    <div>
      <el-dialog
        :title="diaTitle_timeType"
        :visible.sync="dia_timeType"
        width="40%"
        @close="handleClose_timeType"
      >
        <div>
          <el-table
            :data="timeTypeData"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange_timeType"
          >
            <el-table-column type="selection" width="60"> </el-table-column>
            <el-table-column prop="createTime" label="创建时间">
            </el-table-column>
            <el-table-column
              v-if="timeType == 1"
              prop="number"
              label="入库单编号"
            ></el-table-column>
            <el-table-column
              v-if="timeType == 2"
              prop="number"
              label="出库单编号"
            ></el-table-column>
            <el-table-column
              v-if="timeType == 3"
              prop="number"
              label="打包称重单编号"
            ></el-table-column>
            <el-table-column
              v-if="timeType == 2"
              prop="billStorepackNumber"
              label="仓库打包单编号"
            ></el-table-column>
            <el-table-column
              v-if="timeType == 3"
              prop="channelNumber"
              label="渠道单号"
            ></el-table-column>
            <el-table-column
              v-if="false"
              prop="packageId"
              label="包裹id"
            ></el-table-column>
            <el-table-column
              v-if="false"
              prop="intoStorageId"
              label="入库单id"
            ></el-table-column>
            <el-table-column
              v-if="false"
              prop="outStorageId"
              label="出库单id"
            ></el-table-column>
            <el-table-column
              v-if="false"
              prop="storepackId"
              label="打包单id"
            ></el-table-column>
          </el-table>
          <!-- 分页组件 -->
          <paging
            ref="pags"
            :pageNum="pageNum_timeType"
            :total="total_timeType"
            :sizeList="sizeList_timeType"
            :size="size_timeType"
            @handleSizeChange="handleSizeChange_timeType"
            @handleCurrentChange="handleCurrentChange_timeType"
          ></paging>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dia_timeType = false">取 消</el-button>
          <el-button type="primary" @click="saveTimeTypesBtn">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog
        :title="dynamicTitle"
        :visible.sync="NewMember"
        :before-close="closebut"
      >
        <div class="newForm">
          <el-form
            ref="countryData"
            :model="countryData"
            :rules="countryData"
            label-width="120px"
          >
            <div class="formbox">
              <div class="queryItem1">
                <el-form-item label="参数编号:">
                  <el-input v-model="countryData.chineseName"></el-input>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item label="参数名称:">
                  <el-input v-model="countryData.englishName"></el-input>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item label="参数标识:">
                  <el-input v-model="countryData.englishName"></el-input>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item label="参数值:">
                  <el-input v-model="countryData.englishName"></el-input>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item label="排序:">
                  <el-input v-model="countryData.englishName"></el-input>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item label="参数备注:">
                  <el-input
                    v-model="countryData.englishName"
                    type="textarea"
                    :rows="1"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
          </el-form>
        </div>
        <div class="footbox">
          <el-button class="seniorbut" @click="confirmbut">确认</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { apiList, Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
import draggable from "vuedraggable"; //拖拽组件
import paging from "@/components/pagings.vue";
import screenfull from "screenfull";

export default {
  inject: ["reload"],
  components: {
    // mytables,
    draggable,
    paging,
  },
  data() {
    return {
      time1: "",
      tape: "add",
      countryData: {
        areaContryId: "",
        chineseName: "",
        englishName: "",
        numberCode: "",
      },
      NewMember: false, //新建会员弹窗
      queryData: {
        //
        areaContryNameCn: "", //国家中文
        areaContryNameEn: "", //国家英文
      },
      times: [], //开始结束时间
      timeType: "0", //时间类型
      memberId: "", //会员号
      memberId_s: "", //弹窗输入的会员号
      daBaoPiCi: "", //打包批次号
      daBaoPiCi_s: "", //弹窗打包批次号
      rejectionStatus: "", //拒收状态
      packageIds_js: [], //拒收包裹id,
      packageIds_sqdb: [], //申请打包包裹id,
      packageIds_times: [], //各种时间的包裹id,

      // 时间类型
      timeTypes: [
        {
          value: "0",
          label: "添加日期",
        },
        {
          value: "1",
          label: "入库日期",
        },
        {
          value: "2",
          label: "出库日期",
        },
        {
          value: "3",
          label: "打包日期",
        },
      ],
      //包裹状态
      packageStatusList: [],
      //拒签状态
      rejectionStatusList: [],
      //验货状态
      inspectionStatusList: [],

      // ================
      pageNum: 1, //页码
      total: 0, //总条数
      sizeList: [10, 20, 50, 100, 200],
      size: 50, //一页几条
      nextPage: false, //是否有下一页
      show_sortTableHeard: false, //显示弹窗
      show_upload: false, //显示上传弹窗
      myTableHeard: [], //我的表头
      originalTabelHeadeTitle: [],
      selectLength: 0, //显示的长度
      allCheck: false, //全选
      tableHeigth: "50vh",
      tabelHeadeTitle: [
        {
          name: "参数编号",
          field: "",
          width: "120",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "参数名称",
          field: "",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "参数表示",
          field: "",
          width: "120",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "参数说明",
          field: "",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "值",
          field: "",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "排序",
          field: "",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "备注",
          field: "PackageStatus",
          width: "120",
          sort: 7,
          isShow: true,
          isTotal: false,
        },
        {
          name: "新建时间",
          field: "storepackStatusShow",
          width: "120",
          sort: 8,
          isShow: true,
          isTotal: false,
        },
        {
          name: "申请人",
          field: "storepackStatusShow",
          width: "120",
          sort: 8,
          isShow: true,
          isTotal: false,
        },
        {
          name: "修改时间",
          field: "storepackStatusShow",
          width: "120",
          sort: 8,
          isShow: true,
          isTotal: false,
        },
        {
          name: "修改人",
          field: "storepackStatusShow",
          width: "120",
          sort: 8,
          isShow: true,
          isTotal: false,
        },
      ],
      tableData: [],
      totalArr: [], //需要合计的字段
      className: "", //导出需要
      fieldName: [], //下载字段名
      head: [], //下载表头字段
      fileList: [],
      uploadUrl: "", //上传路径
      sortName: "模板名", //排序名
      pageGroupName: "canshushezhi", //页面标识
      selectTableData: [], //选中的数据
      length: null,
      //===============

      memberInfoData: [], //会员信息
      pageNum_member: 1, //页码
      total_member: 0,
      sizeList_mb: [10, 20, 50, 100, 200],
      size_member: 10, //一页几条
      selectMemberData: [], //选中的会员信息
      // 根据时间获取不同单的信息
      timeTypeData: [], //会员信息
      pageNum_timeType: 1, //页码
      total_timeType: 0,
      sizeList_timeType: [10, 20, 50, 100, 200],
      size_timeType: 10, //一页几条
      selectdanhaoData: [], //选中的单号信息
      dia_timeType: false,
      diaTitle_timeType: "", //弹窗标题
      // 根据拒收状态获取拒收单的信息
      juShouInfoData: [], //会员信息
      pageNum_js: 1, //页码
      total_js: 0,
      sizeList_js: [10, 20, 50, 100, 200],
      size_js: 10, //一页几条
      selectJSData: [], //选中的单号信息
      dia_jushou: false,
      // 打包批次号-目的地-渠道代理获取申请打包单信息
      sqdbData: [], //会员信息
      pageNum_sqdb: 1, //页码
      total_sqdb: 0,
      sizeList_sqdb: [10, 20, 50, 100, 200],
      size_sqdb: 10, //一页几条
      selectSqdbData: [], //选中的单号信息
      dia_sqdb: false,
      endtime: "",
      sarttime: "",

      isActive: true, //输入框绑定动态class
      judge: false, //输入框绑定动态class
      MoreConditions: "更多条件", //输入框绑定动态class
      fullscreen: false,
      TableStyle: true,
      dynamicTitle: "",
    };
  },
  updated() {
    if (this.show_sortTableHeard) {
      // 排序表头
      let arr = this.tabelHeadeTitle;
      for (var i = 0; i < arr.length; i++) {
        arr[i].sort = i + 1;
      }
      this.tabelHeadeTitle = arr;
    }
  },
  created() {
    this.uploadUrl = this.common.uploadUrl;
    if (this.originalTabelHeadeTitle.length == 0) {
      this.originalTabelHeadeTitle = JSON.parse(
        JSON.stringify(this.tabelHeadeTitle)
      );
    }
    this.getGroupFindFieldSort(); //获取用户自定义表头
    // this.myTableHeard = this.tabelHeadeTitle;

    this.endtime = tools.getCurrentDate(); //今天的时间;
    // this.endtime = tools.getFlexDate(1); //今天+1的时间;
    this.sarttime = tools.getFlexDate(-3); //三天前
    this.times = [this.sarttime, this.endtime];
  },
  mounted() {
    const that = this;
    this.getData();
  },
  watch: {},
  methods: {
    //关闭弹窗
    closebut() {
      this.NewMember = false;
      this.countryData = {
        areaContryId: "",
        chineseName: "",
        englishName: "",
        numberCode: "",
      };
    },
    //新增
    addMember() {
      this.dynamicTitle = "新建";
      this.NewMember = true;
    },
    //修改
    modifybut() {
      if (this.length > 1) {
        this.$message.warning("请勾选一条数据");
      } else if (this.length < 1) {
        this.$message.warning("请勾选一条数据");
      } else {
        this.dynamicTitle = "修改";
        this.NewMember = true;
        this.countryData.numberCode = this.selectTableData.numberCode;
        this.countryData.chineseName = this.selectTableData.chineseName;
        this.countryData.englishName = this.selectTableData.englishName;
      }
    },
    //新建国家
    confirmbut() {
      let countryData = this.countryData;

      let parm = {
        areaContryId: countryData.areaContryId,
        chineseName: countryData.chineseName,
        englishName: countryData.englishName,
        numberCode: countryData.numberCode,
      };
      let signarr = tools.getSign(parm);
      countryData.sign = signarr;
      Api.addOrUpdateAreaContry(countryData).then((res) => {});
    },
    //表格样式i
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return "warning-row";
      }
    },

    handleCommandTabs(m) {
      if (m == "a") {
        this.myModel();
      } else if (m == "b") {
        this.exportBtn();
      } else if (m == "c") {
        this.exportTemplateDownload();
      } else if (m == "d") {
        this.upLoadBtn();
      } else {
        this.screen();
      }
    },
    //全屏模式
    screen() {
      // if (screenfull.isEnabled) {
      //     screenfull.toggle(this.$refs.tableWrapper);
      // }
    },
    // 阻止F11默认事件
    keydown(event) {
      if (event.keyCode === 122) {
        event.preventDefault();
        event.returnValue = false;
      }
    },

    /**
     * 响应屏幕变化事件，给isFullscreen赋值，标识是否全屏
     * IE中bug: 调试器打开，且与页面不分离时 -- 无效
     * **/
    onresize(event) {
      // 利用屏幕分辨率和window对象的内高度来判断兼容IE
      let winFlag = window.innerHeight === window.screen.height;
      // 利用window全屏标识来判断 -- IE无效
      let isFull = window.fullScreen || document.webkitIsFullScreen;

      if (isFull === undefined) {
        this.isFullscreen = winFlag;
      } else {
        this.isFullscreen = winFlag || isFull;
      }
      //console.log('winFlag || isFull', winFlag, isFull)
      if (winFlag == true && isFull == true) {
        this.fullscreen = true;
        this.TableStyle = false;

        //console.log(this.tableHeigth)
      } else {
        this.fullscreen = false;
        this.TableStyle = true;
      }
    },

    //输入框绑定动态class
    more() {
      this.isActive = !this.isActive;
      this.judge = !this.judge;
      this.fetTableHeight();
      if (this.judge == false) {
        this.MoreConditions = "更多条件";
      } else {
        this.MoreConditions = "收起条件";
      }
    },

    // 输入批次号,回车
    getDaBaoDan(e) {
      // if (this.daBaoPiCi) {
      this.dia_sqdb = true;
    },
    // 打包批次号-目的地-渠道代理获取申请打包单信息
    getPackPackageBillForPackage() {
      let pids = this.daBaoPiCi_s || "";
      if (pids) {
        pids = pids.split(",");
      }
      let param = {
        areaCountryId: "", //国家id
        channelId: "", //渠道id
        number: JSON.stringify(pids), //打包批次号
        pageStart: this.pageNum_sqdb,
        pageTotal: this.size_sqdb,
      };
      Api.getPackPackageBillForPackage(param).then((res) => {
        this.sqdbData = res.data.result.data || [];
        this.size_sqdb = res.data.result.pageTotal || 0;
        this.total_sqdb = res.data.result.pageCount || 0;
      });
    },

    // 输入会员号,回车

    // 根据会员号获取会员信息
    getMemberByMemberId() {
      let mids = this.memberId_s;
      // if (mids) {
      //     mids = mids.split(',')
      // }
      let param = {
        memberId: mids,
        pageStart: this.pageNum_member,
        pageTotal: this.size_member,
      };
      Api.getMemberByMemberId(param).then((res) => {
        this.memberInfoData = res.data.result.data || [];
        this.size_member = res.data.result.pageTotal || 0;
        this.total_member = res.data.result.pageCount || 0;
        this.queryData.userIds = [];
      });
    },
    // 选择拒收状态
    changeRejectStatus(e) {
      this.rejectionStatus = e;
      this.getRejectionBillForPackage();
      this.dia_jushou = true;
    },

    // 根据拒收状态获取拒收单信息
    getRejectionBillForPackage() {
      let param = {
        rejectionStatus: this.rejectionStatus,
        pageStart: this.pageNum_member,
        pageTotal: this.size_member,
      };
      Api.getRejectionBillForPackage(param).then((res) => {
        this.memberInfoData = res.data.result.data || [];
        this.size_member = res.data.result.pageTotal || 0;
        this.total_member = res.data.result.pageCount || 0;
      });
    },
    //关闭会员信息弹窗
    handleClose_member() {
      this.memberInfoData = [];
      this.selectMemberData = [];
    },
    //关闭拒收单信息弹窗
    handleClose_jushou() {
      this.juShouInfoData = [];
      this.selectJSData = [];
    },
    //关闭申请打包信息弹窗
    handleClose_sqdb() {
      this.sqdbData = [];
      this.selectSqdbData = [];
      this.dia_sqdb = false;
    },

    //输入批次号,查询
    daBaoQueryBtn() {
      this.getPackPackageBillForPackage();
    },
    //拒收单==>确定按钮
    saveJuShouBtn() {
      let arr = [];
      for (var i = 0; i < this.selectJSData.length; i++) {
        arr.push(this.selectJSData[i].packageId);
      }
      this.packageIds_js = arr;
      this.dia_jushou = false;
    },
    //申请打包信息==>确定按钮
    savesqdbBtn() {
      let arr = [];
      for (var i = 0; i < this.selectJSData.length; i++) {
        arr.push(this.selectJSData[i].packageId);
      }
      this.packageIds_sqdb = arr;
      this.dia_jushou = false;
    },
    //会员信息==>选择表格操作
    handleSelectionChange_menber(e) {
      this.selectMemberData = e;
    },
    //拒收单信息==>选择表格操作
    handleSelectionChange_js(e) {
      this.selectJSData = e;
    },
    //申请打包==>选择表格操作
    handleSelectionChange_sqdb(e) {
      this.selectSqdbData = e;
    },
    //选择表格操作
    handleSelectionChange_timeType(e) {
      this.selectdanhaoData = e;
      //console.log(e);
    },
    // 表格选择事件
    handleSelectionChange(e) {
      this.selectTableData = e[0];
      this.length = e.length;
    },

    //关闭时间单号信息弹窗
    handleClose_timeType() {
      this.timeTypeData = [];
      this.selectdanhaoData = [];
    },
    //根据时间xxx==>确定按钮
    saveTimeTypesBtn() {
      let arr = [];

      for (var i = 0; i < this.selectdanhaoData.length; i++) {
        arr.push(this.selectdanhaoData[i].packageId);
      }
      this.packageIds_times = arr;
      this.dia_timeType = false;
    },

    /**
     * =========================按钮部分的方法=>开始??????????????????????????????????
     */

    /**
     * =========================按钮部分的方法=>结束??????????????????????????????????
     */

    // ===============================================导出部分=开始======
    // 上传导出模板
    uploadSectionFile(param) {
      const file = param.file,
        fileName = file.name;

      // 根据后台需求数据格式
      const form = new FormData();
      // 文件对象
      form.append("file", file);
      // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      form.append("fileName", fileName);
      let signArr = [
        {
          key: "fileName",
          val: fileName,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      form.append("sign", sign);
      // 项目封装的请求方法，下面做简单介绍
      Api.uploadFile(form)
        .then((res) => {
          if (res.data.status == "success") {
            let fileName = res.data.result.fileName;

            let signArr = [
              {
                key: "groupName",
                val: this.pageGroupName,
              },
              {
                key: "templateName",
                val: this.sortName,
              },
              {
                key: "fileName",
                val: fileName,
              },
            ];
            let sign_ = tools.getMD5Sign(signArr);
            Api.saveExportTemplate({
              groupName: this.pageGroupName,
              templateName: this.sortName,
              fileName: fileName,
              sign: sign_,
            }).then((res) => {
              this.$message.success(res.data.message || "更新成功");
              this.show_upload = false;
            });
          }
        })
        .catch((err) => {});
    },

    // 下载用户导出模板
    exportTemplateDownload() {
      var fieldName = this.fieldName;
      var head = this.head;
      if (head.length == 0) {
        let fieldNames = [];
        let heads = [];
        for (var i = 0; i < this.myTableHeard.length; i++) {
          if (this.myTableHeard[i].isShow) {
            fieldNames.push(this.myTableHeard[i].field);
            heads.push(this.myTableHeard[i].name);
          }
        }
        fieldName = fieldNames;
        head = heads;
      }
      //加密数组格式
      let signArr = [
        {
          key: "fieldName",
          val: fieldName,
        },
        {
          key: "head",
          val: head,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      let param = {
        fieldName: fieldName,
        head: head,
        sign: sign,
      };
      Api.exportTemplateDownload(param).then((res) => {
        let downloadurl = res.data.result.fileUrl || "";
        //下载(这里其实是直接访问)
        var a = document.createElement("a");
        a.setAttribute("href", downloadurl);
        a.setAttribute("target", "_blank");
        a.click();
      });
    },
    // 真正导出
    templateDataExport(customizedExportId) {
      let that = this;
      let datas = that.tableData;
      let parm = {
        customizedExportId: customizedExportId, //模板id
        className: that.className,
        data: datas,
      };
      let signarr = tools.getSignArr(parm);
      let signs_e = tools.getMD5Sign(signarr);

      Api.templateDataExport({
        customizedExportId: customizedExportId,
        className: that.className,
        data: JSON.stringify(datas),
        sign: signs_e,
      }).then((res) => {
        //下载(这里其实是直接访问)
        let downloadurl = res.data.result.fileUrl;
        var a = document.createElement("a");
        a.style.display = "none";
        a.href = downloadurl;
        a.target = "_blank";
        a.setAttribute("download", "综合查询.xlsx"); //指定下载后的文件名，防跳转
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
    },
    // 上传按钮
    upLoadBtn() {
      this.show_upload = true;
    },
    // 点击导出按钮
    exportBtn() {
      let that = this;
      let fieldName = [];
      let head = [];
      for (var i = 0; i < this.myTableHeard.length; i++) {
        if (this.myTableHeard[i].isShow) {
          fieldName.push(this.myTableHeard[i].field);
          head.push(this.myTableHeard[i].name);
        }
      }
      this.fieldName = fieldName;
      this.head = head;

      // 1,先获取用户导出模板
      Api.getUserExportTemplate({
        groupName: this.pageGroupName,
      }).then((res) => {
        if (res.data.result && res.data.result[0].customizedExportId) {
          // 有自定义模板

          let customizedExportId = res.data.result[0].customizedExportId;
          that.templateDataExport(customizedExportId);
        } else {
          //没有自定义模板,就是下载一个
          tools.dynamicExport(
            this.myTableHeard,
            this.className,
            this.tableData,
            "其他支出列表"
          );
        }
      });
    },
    // ===============================================导出部分=结束======

    /**
     * ******************************计算表格高度**开始*************************
     */
    // 重置table高度
    resetHeight() {
      return new Promise((resolve, reject) => {
        this.tableHeigth = 0;
        resolve();
      });
    },
    // 设置table高度
    fetTableHeight() {
      this.resetHeight().then((res) => {
        this.tableHeigth = this.getHeight();
        //console.log(this.tableHeigth + "表格高度");
        this.$nextTick(() => {
          this.$refs.mytable.doLayout();
        });
      });
    },
    // 获取表格高度
    getHeight() {
      let windonHeight = document.body.clientHeight;
      let queryHeigth;
      let btnListHeight = this.$refs.btnList.offsetHeight + 16; // 按钮列表  16==上下的margin
      if (this.judge == true) {
        queryHeigth = this.$refs.queryCriteria.offsetHeight; //查询框
      } else {
        queryHeigth = 50;
      }
      let pagsHeigth = 40; //分页组件
      var otherHeight = queryHeigth + btnListHeight + pagsHeigth + 60 + 10; //60是顶部导航 ,10==预留空位
      return Math.floor(windonHeight - otherHeight) || "50vh"; //
    },
    /**
     * ******************************计算表格高度**结束*************************
     */

    // 刷新页面==>
    reload_s() {
      this.reload(); //App.Vue里面的方法
    },
    // 获取数据
    getData() {
      let formData = this.queryData;
      let that = this;
      formData.pageStart = that.pageNum;
      formData.pageTotal = that.size;
      Api.getAreaContryList(formData).then((res) => {
        //console.log(111, res.data.result.data);
        if (res.data.status == "success") {
          that.className = res.data.result.className || "";
          that.tableData = res.data.result.data || [];
          that.total = res.data.result.pageCount || 0;
          this.fetTableHeight();
        }
      });
    },
    // 查询按钮
    queryBtn_ok() {
      //console.log(this.queryData);
      // let orderNos = this.queryData.val1;
      // this.getStr(orderNos);
      // 别的参数暂时没有
      this.pageNum = 1;
      this.getData();
    },
    // 重置按钮
    queryBtn_cz() {
      this.queryData = {
        memberId: "", //会员号
        nickname: "", //会员名称
      };
    },

    // 获取自定义的排序,没有就用默认的
    getGroupFindFieldSort() {
      let that = this;
      Api.getGroupFindFieldSort({
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
      }).then((res) => {
        let arr = res.data.result || [];
        if (arr.length > 0) {
          if (arr[0].sortObject) {
            that.myTableHeard = JSON.parse(arr[0].sortObject);
            this.sortName = arr[0].sortName;
          }
        } else {
          that.myTableHeard = that.tabelHeadeTitle;
        }
        let totalArr = [];
        //提取需要合计的字段
        for (var i = 0; i < that.myTableHeard.length; i++) {
          if (that.myTableHeard[i].isTotal) {
            totalArr.push(that.myTableHeard[i].field);
          }
        }
        that.totalArr = totalArr;
      });

      that.$nextTick(() => {
        that.$refs.mytable.doLayout();
      });
    },
    // 设置模板按钮
    myModel() {
      let that = this;
      that.show_sortTableHeard = true;
      that.tabelHeadeTitle = JSON.parse(JSON.stringify(that.myTableHeard));
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },

    // 排序==>取消按钮
    cancelSortHeard() {
      this.show_sortTableHeard = false;
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
    },
    // 排序==>确认按钮
    confirmOk() {
      this.myTableHeard = this.tabelHeadeTitle;
      let sortObject = this.myTableHeard;
      let signs = [
        {
          key: "sortName",
          val: this.sortName, //综合查询接口路径
        },
        {
          key: "groupName",
          val: this.pageGroupName,
        },
        {
          key: "sortObject",
          val: JSON.stringify(sortObject),
        },
      ];

      let sign = tools.getMD5Sign(signs);

      let param = {
        // apiPath: apiList.api_packageList, //综合查询接口路径
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
        sortName: this.sortName, //综合查询接口路径
        sortObject: JSON.stringify(sortObject),
        sign: sign,
      };
      Api.pushFindFieldSort(param).then((res) => {
        this.$message.success(res.data.message || "操作成功");
        this.getGroupFindFieldSort();
      });
      this.show_sortTableHeard = false; //关闭弹窗
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      this.show_sortTableHeard = false;
    },
    // 点击某一项
    checktab(idx, e) {
      let that = this;
      that.tabelHeadeTitle[idx].isShow = e;
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },
    //全选
    checkAll(e) {
      let that = this;
      this.allCheck = e;
      for (var j = 0; j < this.tabelHeadeTitle.length; j++) {
        this.tabelHeadeTitle[j].isShow = e;
      }
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
    },
    // 合计
    getSummaries(param) {
      let that = this;
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        for (var i = 0; i < that.totalArr.length; i++) {
          if (column.property == that.totalArr[i]) {
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return prev + curr;
                } else {
                  return prev;
                }
              }, 0);
              sums[index] += "";
            } else {
              sums[index] = "---";
            }
          }
        }
      });
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      return sums;
    },

    //分页
    handleSizeChange(val) {
      this.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getData();
    },
    //分页(会员信息的)
    handleSizeChange_mb(val) {
      this.size = val;
      this.getMemberByMemberId();
    },
    handleCurrentChange_mb(val) {
      this.pageNum = val;
      this.getMemberByMemberId();
    },
    //分页(拒收单信息的)
    handleSizeChange_js(val) {
      this.size = val;
      this.getRejectionBillForPackage();
    },
    handleCurrentChange_js(val) {
      this.pageNum = val;
      this.getRejectionBillForPackage();
    },
    //分页(申请打包单信息的)
    handleSizeChange_sqdb(val) {
      this.size = val;
      this.getPackPackageBillForPackage();
    },
    handleCurrentChange_sqdb(val) {
      this.pageNum = val;
      this.getPackPackageBillForPackage();
    },
    //分页(时间类型单信息的)
    handleSizeChange_timeType(val) {
      this.size = val;
      this.getMemberByMemberId();
    },
    handleCurrentChange_timeType(val) {
      this.pageNum = val;
      this.getMemberByMemberId();
    },
  },
};
</script>

<style scoped lang="scss">
.tableBox {
  height: calc(100% - 260px);
}
.colorStyle {
  color: #f00;
}
.mdTitName {
  height: 24px;
  border: 1px solid #d7d7d7;
  text-align: center;
  min-width: 100px;
}
.queryCriteria {
  .queryItem_one {
    .queryTextarea {
      border: 1px solid #d7d7d7;
    }
  }
  .timeBox {
    border: 1px solid #d7d7d7;
  }
}
.newForm {
  width: 100%;
  display: flex;
}

.queryItem1 {
  width: 60%;
  margin: 0 10%;
  margin-top: 10px;
}

.queryItem2 {
  width: 100%;
}
.footbox {
  margin-top: 20px;
  width: 100%;
  justify-content: center;
}
.formbox {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
</style>
